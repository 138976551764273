<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                d="M4.93 4.93005C3.12 6.74005 2 9.24005 2 12.0001C2 14.7601 3.12 17.2601 4.93 19.0701L6.34 17.6601C4.89 16.2201 4 14.2201 4 12.0001C4 9.79005 4.89 7.78005 6.34 6.34005L4.93 4.93005ZM19.07 4.93005L17.66 6.34005C19.11 7.78005 20 9.79005 20 12.0001C20 14.2201 19.11 16.2201 17.66 17.6601L19.07 19.0701C20.88 17.2601 22 14.7601 22 12.0001C22 9.24005 20.88 6.74005 19.07 4.93005ZM7.76 7.76005C6.67 8.85005 6 10.3501 6 12.0001C6 13.6501 6.67 15.1501 7.76 16.2401L9.17 14.8301C8.45 14.1101 8 13.1101 8 12.0001C8 10.8901 8.45 9.89005 9.17 9.17005L7.76 7.76005ZM16.24 7.76005L14.83 9.17005C15.55 9.89005 16 10.8901 16 12.0001C16 13.1101 15.55 14.1101 14.83 14.8301L16.24 16.2401C17.33 15.1501 18 13.6501 18 12.0001C18 10.3501 17.33 8.85005 16.24 7.76005ZM12 10.0001C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001C10 12.5305 10.2107 13.0392 10.5858 13.4143C10.9609 13.7893 11.4696 14.0001 12 14.0001C12.5304 14.0001 13.0391 13.7893 13.4142 13.4143C13.7893 13.0392 14 12.5305 14 12.0001C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2108 12.5304 10.0001 12 10.0001Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
